@font-face {
  font-family: Poppins-Regular;
  src: url("../family/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins-Medium;
  src: url("../family/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url("../family/Poppins-SemiBold.ttf");
}

html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #fff;
  text-align: center;
  font-family: Poppins-SemiBold;
  max-width: 600px;
  margin: 0 auto;
}
.Regular {
  font-family: Poppins-Regular;
}
.Medium {
  font-family: Poppins-Medium;
}
.SemiBold {
  font-family: Poppins-SemiBold;
}


.home {
  background-image: url("../img/homeBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}
.content {
  min-height: 100vh;
  padding-top: 80px;
  background: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.8))
}

.purchase {
  background-image: url("../img/purchaseBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}
.purchaseSuccess {
  background-image: url("../img/purchaseSuccess.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 180px;
}

.dashboard{
  background-image: url("../img/dashboardBg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.swap {
  background-image: url("../img/swapBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.translate {
  transform: translate(-50%, -50%);
}

.success {
  background-image: url("../img/successBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 180px;
}

.stake {
  background-image: url("../img/stakeBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 200px;
}
.search {
  background-image: url("../img/searchBg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.searchBg2 {
  background-image: url("../img/searchBg2.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.wallet-adapter-dropdown .wallet-adapter-button,
.wallet-adapter-dropdown .wallet-adapter-button-trigger {
  line-height: auto !important;
  height: 32px !important;
  padding: 0 12px !important;
  background-color: rgba(245, 214, 152, 0.3) !important;
  font-size: 12px !important;
  color: #f5d698 !important;
  border-radius: 10px !important;
}
.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
  width: 18px !important;
  height: 18px !important;
}
.wallet-adapter-button-start-icon {
  margin-right: 5px !important;
}
